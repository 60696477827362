<template>
  <div
    class="form-builder__input"
    :class="schema.styleClasses || ''"
  >
    <q-btn
      :color="schema.color || 'dark'"
      :text-color="schema.textColor || 'white'"
      :dark="!!schema.dark"
      :disable="!!schema.disabled"
      :label="schema.label"
      :dense="!!schema.dense"
      :icon="schema.icon"
      :class="schema.btnStyleClasses || ''"
      :size="schema.size || 'md'"
      no-caps
      @click="handleClick"
    />
  </div>
</template>

<script>
export default {
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    handleClick (e) {
      typeof this.schema.onClick === 'function' && this.schema.onClick(e)
    }
  }
}
</script>
